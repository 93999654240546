<template>
  <el-dialog
    title="Reschedule All Orders"
    :visible.sync="setShow"
    @close="close"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Service" prop="service" :rules="validateField()">
            <el-select v-model="form.service" placeholder="Select service">
              <el-option label="All" value="all" />
              <el-option
                v-for="(service, i) in services"
                :key="i"
                :label="`${service.service_name} ${
                  service.slug === 'laundry' ? '(Pickup date)' : ''
                }`"
                :value="service.slug"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="15" class="is-flex-wrap">
        <el-col :md="12">
          <el-form-item
            label="From"
            prop="current_date"
            :rules="validateField()"
          >
            <el-date-picker
              v-model="form.current_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Select from date"
              prefix-icon="eden-icon-calendar"
              :picker-options="{
                disabledDate: disabledDates,
              }"
            />
          </el-form-item>
        </el-col>
        
        <el-col :md="12">
          <el-form-item
            label="To"
            prop="new_current_date"
            :rules="validateField()"
          >
            <el-date-picker
              v-model="form.new_current_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Select to date"
              prefix-icon="eden-icon-calendar"
              :picker-options="{
                disabledDate: disabledDates,
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button plain @click="close">Cancel</el-button>
      <el-button
        type="primary"
        :loading="rescheduling"
        @click="rescheduleOrders"
        >Move Orders</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import orders from "@/requests/orders/index";

export default {
  name: "OrdersReschedule",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        service: "",
        current_date: "",
        new_current_date: "",
      },
      rescheduling: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
  },
  methods: {
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      return time.getTime() < new Date().getTime();
    },
    close() {
      this.rescheduling = false;
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    rescheduleOrders() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        if (this.form.current_date === this.form.new_current_date) {
          this.$message.error("You can't reschedule to the same date.");
          return;
        }
        this.rescheduling = true;

        orders
          .reschedule(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.close();
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.rescheduling = false;
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
